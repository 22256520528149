import API from '../';

interface IDataNSS {
	nss: string;
}

class RestructuringRequestAPI extends API {
	FindCases(data: IDataNSS): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/consultar-caso',
				method: 'POST',
				data,
			})
		);
	}

	AvailabilityAgreements(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/obtener-disponibilidad-convenios',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	ConsulDoc(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/consultar-documento',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	/*GenerarConvenio(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'reestructuras/generar-convenio',
				method: 'POST',
				data: { data: data },
			})
		);
	}*/

	CreateCaseRequest(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/crear-caso',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	RechazoCaseRequest(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/crear-rechazo',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	TypesRejection(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/tipos-rechazo',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	FactorDcp(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/consultar-factor',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	GenerarConvenioBcn(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/generar-convenio',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	firmarConvenio(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/firmar-documento',
				method: 'POST',
				data: { data: data },
			})
		);
	}

	encolarConvenio(data: any): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: 'v1/reestructuras/encolar-convenio',
				method: 'POST',
				data: { data: data },
			})
		);
	}
}

export const restructuringRequestAPI = new RestructuringRequestAPI('');
